<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap35BuildAnHttpClient"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 35: Build an HTTP Client" image-name="consuming-api.jpg" image-alt="Build an HTTP Client"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is the client/server model?</p></li>
<li><p>How to create an HTTP client.</p></li>
<li><p>How to send HTTP requests.</p></li>
<li><p>How to add specific headers to your requests.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Header</p></li>
<li><p>Client/Server</p></li>
<li><p>HTTP</p></li>
</ul>
<div id="client-server" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Client / Server <a href="#client-server"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="example-1" class="anchor"></div>
<h4 data-number="3.0.0.1"><span class="header-section-number">3.0.0.1</span> Example 1 <a href="#example-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Imagine that you have developed a Go Program that is managing your pictures. This program is handling your library of photos on your computer. You might want to share those pictures with other family members; to do that, you can send them an email with all your pictures in it. This solution might be impossible if you have taken ten thousand pictures.</p>
<p>You can upload the content automatically to your favorite social network. The operation could become time-consuming if you have to do it one picture at a time. The other solution might be to plug your program directly to the social network systems to upload the pictures programmatically.</p>
<p>We can do this with the help of the API exposed by the social network. You can push your pictures in a for loop by calling their API directly.</p>
<p>In this case, you will consume an API. You are the <strong>client</strong>. The social network represents the <strong>Server</strong>.</p>
<div id="example-2" class="anchor"></div>
<h4 data-number="3.0.0.2"><span class="header-section-number">3.0.0.2</span> Example 2 <a href="#example-2"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Receiving and processing payments is a difficult task. The vast majority of e-commerce websites will use a payment provider. The website will call the payment provider to initiate card payments. In this context, the website is the client, and the payment provider is the server.</p>
<div id="example-3" class="anchor"></div>
<h4 data-number="3.0.0.3"><span class="header-section-number">3.0.0.3</span> Example 3 <a href="#example-3"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>If you want to integrate into your website a widget that displays the current temperature in San Francisco, you can build an API Client that will request a meteorological API (Server). Then parse the response and finally display it on your website homepage.</p>
<div id="api-call" class="anchor"></div>
<h4 data-number="3.0.0.4"><span class="header-section-number">3.0.0.4</span> API Call <a href="#api-call"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>Calling an API means making an HTTP(s) request to a web server by following a precise documentation.</p>
<p>The terms client and server are important, and you must memorize them.</p>
<ul>
<li><p>As a <strong>client</strong> we use (or consume) an API.</p></li>
<li><p>The <strong>server</strong> is a computer program that is designed to accept and respond to API Calls of clients.</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/client_server.png')" alt="Client-Server"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Client-Server</figcaption>
</figure>
<div id="what-is-a-rest-api" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> What is a REST API <a href="#what-is-a-rest-api"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>REST stands for Representational State Transfer. Roy Fielding created the term in his doctorate thesis in 2000. Fielding wanted to develop a “model for the modern Web architecture”<b-link class="citation" data-cites="fielding2000architectural" href="#fielding2000architectural" >[@fielding2000architectural]</b-link>. He introduced REST which is an “architectural style”, a set of architectural constraints to build network-based applications.</p>
<p>Note that Fielding has defined the concept of REST, but we used those architectural constraints before his thesis. REST is now widely used among the web community; we even invented an adjective for defining APIs that follow this architectural style: RESTful.</p>
<p>The API consumer and its producer (the developers behind it) can benefit from these constraints. The API producers follow the guidelines, but they are also free to ignore constraints. On the other side, APIs consumers can build software quicker because, at the technical level, APIs are built following the same standards. They are plenty of libraries (modules) that are freely available to build either the server part or the client part.</p>
<p>What are exactly those constraints?</p>
<ul>
<li><p>Communication is <strong>stateless</strong> : it means that the server doesn’t keep information about sessions, each request is treated individually. The server does not remember your previous requests. Therefore each request might contain all the necessary information for the server to handle it.</p></li>
<li><p><strong>Cacheable responses</strong> : the responses sent by the server can be cached to be used for later requests.</p></li>
<li><p><strong>Uniform interface</strong> : REST APIs always have the same interface for consumers. This uniform interface is built following three sub-constraints :</p>
<ul>
<li><p><u>Identification of ressources</u> : Each ressource of your service (an order, a product, a photo) is addressed by an unique ressource identifier (URI).</p>
<ul>
<li>For instance : <span v-highlightjs><code class="go" v-pre style="display: inline">https://maximilien-andile.com/product/2</code></span> represent the URL of the product number 2 resource.</li>
</ul></li>
<li><p><u>Manipulation of resources through representations</u> : when you use an API, you are manipulating a representation of the resource. The resource can be represented as JSON, XML, YAML...</p></li>
<li><p><u>Self-descriptive messages</u> : messages that are exchanged between the client and server must contain all the necessary information to be correctly handled, and this information should be transmitted in a way that clients can easily understand it and servers. To do so, APIs builders use HTTP methods (the norm HTTP 1.1 defines eight methods), HTTP Headers, the body of the request but also the query string.</p></li>
<li><p><u>Hypermedia as the engine of application state</u> (<strong>HATEOAS</strong>): the web server can send links that points to the resource itself or the linked resources. For instance, if a consumer sends a request to <span v-highlightjs><code class="go" v-pre style="display: inline">https://maximilien-andile.com/product/2</code></span> to get information about product 2, the web server can also send to the client links to the product variants (color, size, ...).</p></li>
</ul></li>
<li><p><strong>Layered system</strong> : when building an API, you can have a specific system to handle authentication of your requests, another component responsible for retrieving the data in databases and formatting the response... From the client’s point of view, those layers should be invisible.</p></li>
<li><p><strong>Code on demand</strong> : this last constraint is optional, and in reality, it’s not used intensively in the industry. It allows a web server to return scripts to clients. Clients can then execute those scripts in their system. The advertisement industry uses this feature a lot; they expose APIs to distribute Javascript or HTML banners to their clients.</p></li>
</ul>
<p>You can find an overview of HTTP in the section <a href="#sec:What-is-HTTP" data-reference-type="ref" data-reference="sec:What-is-HTTP">[sec:What-is-HTTP]</a>.</p>
<div id="a-basic-http-client" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> A basic HTTP Client <a href="#a-basic-http-client"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The <strong>client</strong> is the party that will send the request to the <strong>server</strong>.</p>
<p>We will use the standard Go client from <span v-highlightjs><code class="go" v-pre style="display: inline">net/http</code></span><a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>. To initialize an HTTP client, simply create a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">http.Client</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >// consuming-api/simple/main.go
package main

import (
    &quot;fmt&quot;
    &quot;io/ioutil&quot;
    &quot;net/http&quot;
    &quot;time&quot;
)

func main() {
    c := http.Client{Timeout: time.Duration(1) * time.Second}
    resp, err := c.Get(&quot;https://www.google.com&quot;)
    if err != nil {
        fmt.Printf(&quot;Error %s&quot;, err)
        return
    }
    defer resp.Body.Close()
    body, err := ioutil.ReadAll(resp.Body)
    fmt.Printf(&quot;Body : %s&quot;, body)
}</code></pre>
<p>When you create an HTTP client, you can specify the following options : <span v-highlightjs><code class="go" v-pre style="display: inline">Transport</code></span> (type : <span v-highlightjs><code class="go" v-pre style="display: inline">http.RoundTripper</code></span>): You can custom the way your HTTP requests will be executed by setting this field to a type that implements the type interface <span v-highlightjs><code class="go" v-pre style="display: inline">http.RoundTripper</code></span>.</p>
<ul>
<li>This is an advanced usage that you do not need most of the time. When you create the server, you can omit this parameter. In that case, <span v-highlightjs><code class="go" v-pre style="display: inline">DefaultTransport</code></span> is used. If you are curious, its source code is located here: https://golang.org/src/net/http/transport.go</li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">CheckRedirect</code></span> (type <span v-highlightjs><code class="go" v-pre style="display: inline">func(req *Request, via []*Request)</code></span>): we can use this field to define a function that will be called each time your request is redirected. A redirection is triggered when the server sends a special response. The response received has a special Code (HTTP Status Code) (see the section about HTTP basics to get more information about redirections).</p>
<ul>
<li>The use-case here is checking things before following redirections.</li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Jar</code></span> (type <span v-highlightjs><code class="go" v-pre style="display: inline">CookieJar</code></span>): with this field, you can add cookies to your request. Cookies are passed to the server. The server himself can add cookies to the request. Those “inbound cookies” will be added to the jar.</p>
<ul>
<li><p>A cookie is a piece of data stored on the client side by web browsers.</p>
<ul>
<li><p>You can set cookies via javascript code</p></li>
<li><p>The server can also set them via a specific header.</p></li>
</ul></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Timeout</code></span>(type <span v-highlightjs><code class="go" v-pre style="display: inline">time.Duration</code></span>): your client will open a connection to the server via HTTP; the server may take some time to answer your request. This field will define a maximum time to wait for the response. If you do not specify a timeout, there is no timeout by default. This can be dangerous for the user-perceived performance of your service. It’s better, in my opinion, to display an error than to make the user wait indefinitely. Note that this time includes:</p>
<ul>
<li><p>Connection time (the time needed to connect to the distant server)</p>
<ul>
<li><p>Time taken by redirects (if any)</p></li>
<li><p>Time is taken to read the response body.</p></li>
</ul></li>
</ul>
<p>We build an HTTP Client with the DefaultTransport, no CheckRedirect function, no cookies but a timeout set to 1 second.</p>
<p>With that client, we can send requests to the web server. In our example, we make an http request with the GET method to the URL https://www.google.com :</p>
<pre v-highlightjs><code class="go" v-pre >resp, err := c.Get(&quot;https://www.google.com&quot;)
if err != nil {
    fmt.Errorf(&quot;Error %s&quot;, err)
    return
}</code></pre>
<p>You have a similar API to use the HTTP <span v-highlightjs><code class="go" v-pre style="display: inline">POST</code></span> method :</p>
<pre v-highlightjs><code class="go" v-pre >myJson := bytes.NewBuffer([]byte(`{&quot;name&quot;:&quot;Maximilien&quot;}`))
resp, err := c.Post(&quot;https://www.google.com&quot;, &quot;application/json&quot;, myJson)
if err != nil {
    fmt.Errorf(&quot;Error %s&quot;, err)
    return
}</code></pre>
<p>Note that in the case of the POST method, in addition to the URL, we must define the body of the request and the content-type of the data. The body of the request is the data we send. Why not simply put a JSON string? Why do we use a <span v-highlightjs><code class="go" v-pre style="display: inline">bytes.Buffer</code></span> ? That’s because this argument must implement the interface <span v-highlightjs><code class="go" v-pre style="display: inline">io.Reader</code></span> !</p>
<p>To make an <span v-highlightjs><code class="go" v-pre style="display: inline">HEAD</code></span> request the syntax is close :</p>
<pre v-highlightjs><code class="go" v-pre >resp, err = c.Head(&quot;https://www.google.com&quot;)
if err != nil {
    fmt.Errorf(&quot;Error %s&quot;, err)
    return
}
fmt.Println(resp.Header)</code></pre>
<p>Note than a <span v-highlightjs><code class="go" v-pre style="display: inline">HEAD</code></span> request does not return a body. However, it returns the headers received in response. (this method is used to test URL, to check if they are valid, accessible or recently modified <b-link class="citation" data-cites="fielding1999rfc" href="#fielding1999rfc" >[@fielding1999rfc]</b-link>).</p>
<p>Let’s get back to the end of our script :</p>
<pre v-highlightjs><code class="go" v-pre >defer resp.Body.Close()
body, err := ioutil.ReadAll(resp.Body)
fmt.Printf(&quot;Body : %s&quot;, body)</code></pre>
<p>We close the body when the function returns with <span v-highlightjs><code class="go" v-pre style="display: inline">defer resp.Body.Close()</code></span><strong>.</strong><span v-highlightjs><code class="go" v-pre style="display: inline">resp.Body</code></span> is a stream of data read by the http client. Do not forget to add this closing instruction; otherwise, the client might not reuse a potential persistent connection to the server (cf. https://golang.org/pkg/net/http/#Client.Do).</p>
<div id="request-headers" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Request Headers <a href="#request-headers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="what-is-a-request-header" class="anchor"></div>
<h2 data-number="6.1"><span class="header-section-number">6.1</span> What is a request header? <a href="#what-is-a-request-header"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Request Headers, or simply headers, are fields that are attached to the request. The client (your program) can use those fields to transmit additional pieces of information to the server about the request or himself <b-link class="citation" data-cites="fielding1999hypertext" href="#fielding1999hypertext" >[@fielding1999hypertext]</b-link>.</p>
<p>The specification of HTTP 1/1 allows you to use several header fields. Here is a shortlist of headers that are habitually used : <span v-highlightjs><code class="go" v-pre style="display: inline">Content-Type</code></span> : it indicates to the server what type of media you will transmit in the request</p>
<ul>
<li><p>Value example :</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">application/json; charset=utf-8</code></span> =&gt; for JSON data encoded in UTF-8</p></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Content-Length</code></span> : this is the size of the message sent (in bytes)</p>
<ul>
<li><p>Value Example :</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">42</code></span></p></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">User-Agent</code></span> : the name and the version of the program that sends the request.</p>
<ul>
<li><p>Value Example :</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">curl/7.16.3 libcurl/7.16.3 OpenSSL/0.9.7l zlib/1.2.3</code></span> If you use curl to perform your request</p>
<pre v-highlightjs><code class="go" v-pre >Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/88.0.4324.182 Safari/537.36</code></pre>
<p>If you use chrome on a MacBook</p></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Accept</code></span> : specify which media types are acceptable for the response</p>
<ul>
<li><p>Value Example</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">*/*</code></span> All media types are accepted</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">application/json</code></span> : is you accept only JSON</p></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Accept-Encoding</code></span> : specify which types of encoding are acceptable in the response.</p>
<ul>
<li><p>Value Example</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">gzip</code></span> gzipped (compressed) content is accepted</p></li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Authorization</code></span> : in this header, the sender can specify its credentials (API key, username/password, JWT .…)</p>
<ul>
<li><p>Value Example</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Bearer cGFydDJibGEcGFydDJibGEcGFydDJibGEcGFydDJibGE=.cGFydDJcGFydDJibGEcGFydDJibGEcGFydDJibGEibGE=.eW9sbcGFydDJibGEcGFydDJibGEcGFydDJibGEw==</code></span></p></li>
</ul>
<div id="how-to-add-a-header-to-the-request" class="anchor"></div>
<h2 data-number="6.2"><span class="header-section-number">6.2</span> How to add a header to the request <a href="#how-to-add-a-header-to-the-request"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To add a header, you have to build the request. It’s more verbose but building the method allows you to gain more control.</p>
<pre v-highlightjs><code class="go" v-pre >// consuming-api/request-building/main.go
package main

import (
    &quot;fmt&quot;
    &quot;io/ioutil&quot;
    &quot;net/http&quot;
    &quot;time&quot;
)

func main() {

    c := http.Client{Timeout: time.Duration(1) * time.Second}
    req, err := http.NewRequest(&quot;GET&quot;, &quot;http://www.google.fr&quot;, nil)
    if err != nil {
        fmt.Printf(&quot;error %s&quot;, err)
        return
    }
    req.Header.Add(&quot;Accept&quot;, `application/json`)
    resp, err := c.Do(req)
    if err != nil {
        fmt.Printf(&quot;error %s&quot;, err)
        return
    }
    defer resp.Body.Close()
    body, err := ioutil.ReadAll(resp.Body)
    fmt.Printf(&quot;Body : %s&quot;, body)
}</code></pre>
<p>We have to create a client and then use the <span v-highlightjs><code class="go" v-pre style="display: inline">http.NewRequest</code></span> method which has the following signature :</p>
<pre v-highlightjs><code class="go" v-pre >(method, url string, body io.Reader) (*Request, error)</code></pre>
<p>The first parameter is the <strong>method</strong> which can be one of the following :</p>
<ul>
<li><p>OPTIONS</p></li>
<li><p>GET</p></li>
<li><p>HEAD</p></li>
<li><p>POST</p></li>
<li><p>PUT</p></li>
<li><p>DELETE</p></li>
<li><p>TRACE</p></li>
<li><p>CONNECT</p></li>
</ul>
<p>Then you provide the URL. The body is the third parameter of this method. In our case, we set it to nil because we do not have a body to send. The method will not send the request but will return an <span v-highlightjs><code class="go" v-pre style="display: inline">http.Request</code></span>. With <span v-highlightjs><code class="go" v-pre style="display: inline">req.Header.Add</code></span> (where <span v-highlightjs><code class="go" v-pre style="display: inline">req</code></span> is a struct of type <span v-highlightjs><code class="go" v-pre style="display: inline">htttp.Request</code></span>) you can append a header to the request :</p>
<pre v-highlightjs><code class="go" v-pre >req.Header.Add(&quot;Accept&quot;, `application/json`)</code></pre>
<p>Note that the Header field of a request is under the hood, an element of type <span v-highlightjs><code class="go" v-pre style="display: inline">map[string][]string</code></span> a map where the keys are strings and the values are slices of strings.</p>
<p>The request is sent with <span v-highlightjs><code class="go" v-pre style="display: inline">c.Do(req)</code></span>.</p>
<div id="headers-automatically-sent" class="anchor"></div>
<h2 data-number="6.3"><span class="header-section-number">6.3</span> Headers automatically sent <a href="#headers-automatically-sent"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Go will add some headers automatically to your requests :</p>
<p>For a standard <strong>GET</strong> request :</p>
<pre v-highlightjs><code class="go" v-pre >c.Get(&quot;http://localhost:8091/status&quot;)</code></pre>
<ul>
<li><p>Accept-Encoding : gzip</p></li>
<li><p>User-Agent: Go-http-client/1.1</p></li>
</ul>
<p>For a standard <strong>POST</strong> request :</p>
<pre v-highlightjs><code class="go" v-pre >c.Post(&quot;http://localhost:8091/status&quot;, &quot;application/json&quot;, bytes.NewBuffer([]byte(&quot;42&quot;)))</code></pre>
<ul>
<li><p>Accept-Encoding : gzip</p></li>
<li><p>User-Agent: Go-http-client/1.1</p></li>
<li><p>Content-Length: 2</p></li>
<li><p>Content-Type: application/json</p></li>
</ul>
<div id="real-world-example-the-github-api" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Real-world example: the Github API <a href="#real-world-example-the-github-api"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="first-calls" class="anchor"></div>
<h3 data-number="7.0.1"><span class="header-section-number">7.0.1</span> First calls <a href="#first-calls"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>In this section, We will build an HTTP client that will send requests to the Github API. Github allows you to share code and collaborate on coding projects with other developers.</p>
<p>Github exposes an API to search for projects, to get and update issues of a specific project... The possibilities that are open by the API of GitHub are very large.</p>
<p>The documentation of the Github API is available on this website:</p>
<pre v-highlightjs><code class="go" v-pre >https://developer.github.com/v3</code></pre>
<p>In this example, we will use the v3 API.</p>
<p>The base URL of the API is https://api.github.com/ ; let’s try to do an HTTP GET request on it.</p>
<pre v-highlightjs><code class="go" v-pre >// consuming-api/github/main.go 

c := http.Client{Timeout: time.Duration(1) * time.Second}
req, err := http.NewRequest(&quot;GET&quot;, &quot;https://api.github.com/&quot;, nil)
if err != nil {
    fmt.Printf(&quot;error %s&quot;, err)
    return
}
req.Header.Add(&quot;Accept&quot;, `application/json`)
resp, err := c.Do(req)
if err != nil {
    fmt.Printf(&quot;error %s&quot;, err)
    return
}
defer resp.Body.Close()
body, err := ioutil.ReadAll(resp.Body)
if err != nil {
    fmt.Printf(&quot;error %s&quot;, err)
    return
}
fmt.Printf(&quot;Body : %s \n &quot;, body)
fmt.Printf(&quot;Response status : %s \n&quot;, resp.Status)</code></pre>
<p>When we launch our script, we get the following output :</p>
<pre v-highlightjs><code class="go" v-pre >Body : {&quot;current_user_url&quot;:&quot;https://api.github.com/user&quot;,&quot;current_user_authorizations_html_url&quot;:&quot;https://github.com/settings/connections/applications{/clie
nt_id}&quot;,
...
}

 Response status: 200 OK</code></pre>
<p>The response body is JSON. Note that the three dots means that I have not printed the full body of the response. The status code of our response is “<strong>200 OK</strong>” which means that our request was successful.</p>
<p>The body content suggests that we can ping the GitHub API using other routes. To get the current user, we can request https://api.github.com/user. Let’s try it :</p>
<pre v-highlightjs><code class="go" v-pre >req, err := http.NewRequest(&quot;GET&quot;, &quot;https://api.github.com/user&quot;, nil)</code></pre>
<p>The response is not very glamourous :</p>
<pre v-highlightjs><code class="go" v-pre >Body : {&quot;message&quot;:&quot;Requires authentication&quot;,&quot;documentation_url&quot;:&quot;https://developer.github.com/v3/users/#get-the-authenticated-user&quot;}

Response status: 401 Unauthorized</code></pre>
<p>The status code is “401 Unauthorized”. It means that “the request requires user authentication. The response MUST include a WWW-Authenticate header field (section 14.47) containing a challenge applicable to the requested resource.”<b-link class="citation" data-cites="fielding1999hypertext" href="#fielding1999hypertext" >[@fielding1999hypertext]</b-link>.</p>
<p>Our requested resource (seeing details about the current user) requires that we prove our identity. To do that, we must register to Github. If you already have an account on Github, just go to that web page https://github.com/settings/tokens; if you do not have an account, you can create one if you want. Note that you can follow the tutorial without having a Github account. The idea is to have an idea on how to use an API.</p>
<p>Most of the time, API providers require you to register before using their API to control who has access to the data.</p>
<div id="authorize-your-requests" class="anchor"></div>
<h3 data-number="7.0.2"><span class="header-section-number">7.0.2</span> Authorize your requests <a href="#authorize-your-requests"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Navigate to https://github.com/settings/tokens. On this page, click on the button “Generate new token” :</p>
<figure>
<b-img :src="require('@/assets/images/github_personnal_token.png')" alt="Generate Github Personal Token"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Generate Github Personal Token</figcaption>
</figure>
<p>You will then be asked to give a name to your personal token and select the scopes that Github will attach to your new personal token. Selecting scopes allow you to restrict the usage of your personal token. In our example, we will just select “Gist”. By publishing a Gist, you can share code with other users. We will use the Github API to create Gists.</p>
<figure>
<b-img :src="require('@/assets/images/github_new_token.png')" alt="Generate Github Personal Token"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Generate Github Personal Token</figcaption>
</figure>
<figure>
<b-img :src="require('@/assets/images/create_gist_scope.png')" alt="Create Gist scope Github API"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Create Gist scope Github API</figcaption>
</figure>
<p>Once you got your personal token, you can inject it into your requests. You might ask, where do I put it? If we follow the HTTP 1/1 RFC, then we have to inject it into an “Authenticate header field”. You must always read the API’s documentation because there is no unified way to inject authorization tokens.</p>
<p>The Github API documentation specifies that we should inject the token into the Header Authorization :</p>
<figure>
<b-img :src="require('@/assets/images/authentification_github_api_doc.png')" alt="Authorization header of the GitHub API"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Authorization header of the GitHub API</figcaption>
</figure>
<p>Let’s try it. We will make the same call as before, but this time we will use an authentication header as suggested by the GitHub docs :</p>
<pre v-highlightjs><code class="go" v-pre >// current user
req, err = http.NewRequest(&quot;GET&quot;, &quot;https://api.github.com/user&quot;, nil)
// TODO : handle error
req.Header.Add(&quot;Accept&quot;, `application/json`)
// add header for authentication
req.Header.Add(&quot;Authorization&quot;, fmt.Sprintf(&quot;token %s&quot;, os.Getenv(&quot;TOKEN&quot;))
// ...</code></pre>
<p>The most important line of this listing is :</p>
<pre v-highlightjs><code class="go" v-pre >req.Header.Add(&quot;Authorization&quot;, fmt.Sprintf(&quot;token %s&quot;, os.Getenv(&quot;GITHUB_TOKEN&quot;))</code></pre>
<p>Note that we are not saving the access token directly in our script. This is a very bad idea because if you share your code, you do not want your fellow developers to access your Github account.</p>
<p>Instead, we are using <span v-highlightjs><code class="go" v-pre style="display: inline">os.Getenv("GITHUB_TOKEN")</code></span><strong>.</strong> The Getenv method from the os standard package will retrieve the environment variable names <strong>TOKEN</strong> and parse it. Note that to handle your configuration, you can also use a configuration file. There are very popular packages that handle configuration for you (see chapter <a href="#chap:Application-Configuration" data-reference-type="ref" data-reference="chap:Application-Configuration">[chap:Application-Configuration]</a>).</p>
<p>To pass an environment variable to the program, just prepend <span v-highlightjs><code class="go" v-pre style="display: inline">GITHUB_TOKEN=XXX</code></span> to your command :</p>
<pre v-highlightjs><code class="go" v-pre >$ GITHUB_TOKEN=aabbcc go run main.go</code></pre>
<div id="create-a-gist" class="anchor"></div>
<h3 data-number="7.0.3"><span class="header-section-number">7.0.3</span> Create a gist <a href="#create-a-gist"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>The first thing to do is to read the documentation. You can infer that if we want to create something, it will be a POST; the URI might contain gist, but that’s all. I made a screenshot of the documentation page :</p>
<figure>
<b-img :src="require('@/assets/images/create_gist_documentation.png')" alt="POST /gists documentation"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">POST /gists documentation</figcaption>
</figure>
<p>The endpoint is specified as follow :</p>
<ul>
<li><p>The HTTP verb to use is POST</p></li>
<li><p>The input parameters are</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">files</code></span> (which is an object)</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">description</code></span> : a string</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">public</code></span> which is a boolean value</p></li>
</ul>
<p>Let’s implement this call :</p>
<pre v-highlightjs><code class="go" v-pre >// consuming-api/github-post-gist/main.go 

req, err := http.NewRequest(&quot;POST&quot;, &quot;https://api.github.com/gists&quot;, bytes.NewBuffer(gistRequestJson))
if err != nil {
    fmt.Printf(&quot;%s&quot;, err)
    return
}
req.Header.Add(&quot;Accept&quot;, `application/json`)
// add header for authentication
req.Header.Add(&quot;Authorization&quot;, fmt.Sprintf(&quot;token %s&quot;, os.Getenv(&quot;TOKEN&quot;)))

resp, err := c.Do(req)
if err != nil {
    fmt.Printf(&quot;Error %s&quot;, err)
    return
}</code></pre>
<p>I didn’t inject the requested parameters to see what kind of error message we will have.</p>
<pre v-highlightjs><code class="go" v-pre >Body : {&quot;message&quot;:&quot;Invalid request.\n\nFor &#39;links/0/schema&#39;, nil is not an object.&quot;,&quot;documentation_url&quot;:&quot;https://developer.github.com/v3/gists/#create-a-gi
st&quot;}
 Response status: 422 Unprocessable Entity</code></pre>
<p>We’ve got an error; there might be an input validator somewhere in the Github server that controls the user input.</p>
<p>The parameters will be transmitted in the body of the request. Parameters will be encoded in JSON. To do that, we will create a struct that will hold the parameters.</p>
<pre v-highlightjs><code class="go" v-pre >type GistRequest struct {
    Files       map[string]File `json:&quot;files&quot;`
    Description string          `json:&quot;description&quot;`
    Public      bool            `json:&quot;public&quot;`
}
type File struct {
    Content string `json:&quot;content&quot;`
}</code></pre>
<p>Those struct types will hold the parameters of our request :</p>
<pre v-highlightjs><code class="go" v-pre >files := map[string]File{
        &quot;main.go&quot;: File{&quot;test&quot;}}
gistRequest := GistRequest{
    Files:       files,
    Description: &quot;this is a test&quot;,
    Public:      false}</code></pre>
<p>The variable <span v-highlightjs><code class="go" v-pre style="display: inline">gistRequest</code></span> needs to be transformed into a valid JSON string :</p>
<pre v-highlightjs><code class="go" v-pre >gistRequestJson, err := json.Marshal(gistRequest)
if err != nil {
    fmt.Printf(&quot;%s&quot;, err)
    return
}</code></pre>
<p>Then we just have to populate the body of our request with our brand new JSON. There is just a little difficulty here : <span v-highlightjs><code class="go" v-pre style="display: inline">json.Marshal</code></span> returns a slice of bytes. The signature of <span v-highlightjs><code class="go" v-pre style="display: inline">NewRequest</code></span> is :</p>
<pre v-highlightjs><code class="go" v-pre >func NewRequest(method, url string, body io.Reader) (*Request, error)</code></pre>
<p>The body needs to implement the type interface <span v-highlightjs><code class="go" v-pre style="display: inline">io.Reader</code></span>.</p>
<p>To do that, there is a simple way, using the standard package <span v-highlightjs><code class="go" v-pre style="display: inline">bytes</code></span> :</p>
<pre v-highlightjs><code class="go" v-pre >bytes.NewBuffer(gistRequestJson)</code></pre>
<p>The returned value of <span v-highlightjs><code class="go" v-pre style="display: inline">bytes.NewBuffer</code></span> implements <span v-highlightjs><code class="go" v-pre style="display: inline">io.Reader</code></span>.</p>
<p>We can now launch the request :</p>
<pre v-highlightjs><code class="go" v-pre >req, err := http.NewRequest(&quot;POST&quot;, &quot;https://api.github.com/gists&quot;, bytes.NewBuffer(gistRequestJson))
req.Header.Add(&quot;Accept&quot;, `application/json`)
req.Header.Add(&quot;Authorization&quot;, fmt.Sprintf(&quot;token %s&quot;, os.Getenv(&quot;TOKEN&quot;)))
resp, err := c.Do(req)
//...</code></pre>
<p>The response is a success :</p>
<pre v-highlightjs><code class="go" v-pre >Body : {&quot;url&quot;:&quot;https://api.github.com/gists/c960d211532f7c35aeb0c854892bf108&quot;,...}
 Response status : 201 Created</code></pre>
<p>The Gist has been created, as you can see in figure <a href="#fig:Gist-created-through" data-reference-type="ref" data-reference="fig:Gist-created-through">1</a>. The URI of this new resource is returned in the body response :</p>
<p>https://api.github.com/gists/c960d211532f7c35aeb0c854892bf108. It’s not always the case because APIs do not strictly follow the RFC’s recommendations. Do not be surprised if you do not get the URI in the response. Concerning the response status, it’s also not always “201 Created” but instead “200 OK”.</p>
<p>Most of the time, you have to adapt your client to the API you are integrating. The response status code is not always the same; the authentication method can also be different.</p>
<figure>
<b-img :src="require('@/assets/images/gist_created.png')" alt="Gist created through API call[fig:Gist-created-through]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Gist created through API call<span id="fig:Gist-created-through" label="fig:Gist-created-through">[fig:Gist-created-through]</span></figcaption>
</figure>
<div id="update-a-gist" class="anchor"></div>
<h3 data-number="7.0.4"><span class="header-section-number">7.0.4</span> Update a Gist <a href="#update-a-gist"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>We have created a resource. Now we can try to update it. Most of the time, you update a resource with an HTTP <strong>PUT</strong> or <strong>PATCH</strong> request.</p>
<p>Let’s take a look at the API documentation (<a href="#fig:Update-a-Gist" data-reference-type="ref" data-reference="fig:Update-a-Gist">2</a>).</p>
<figure>
<b-img :src="require('@/assets/images/edit_a_gist.png')" alt="Update a Gist API documentation[fig:Update-a-Gist]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Update a Gist API documentation<span id="fig:Update-a-Gist" label="fig:Update-a-Gist">[fig:Update-a-Gist]</span></figcaption>
</figure>
<p>The request use a PATCH method. The URL is <span v-highlightjs><code class="go" v-pre style="display: inline">/gist/:gist_id</code></span><strong>,</strong>meaning that we have to replace <span v-highlightjs><code class="go" v-pre style="display: inline">gist_id</code></span> by the id of the gist, we want to modify. In the REST API world, each resource has an identifier, here in the case of a gist, the id is denoted <span v-highlightjs><code class="go" v-pre style="display: inline">gist_id</code></span>. In our case the gist_id is c960d211532f7c35aeb0c854892bf108. So the request URL will be:</p>
<pre v-highlightjs><code class="go" v-pre >&quot;https://api.github.com/gists/c960d211532f7c35aeb0c854892bf108&quot;</code></pre>
<p>The parameters are the same as the POST request (to create the gist). The following listing will create the request body :</p>
<pre v-highlightjs><code class="go" v-pre >// consuming-api/github-patch-gist/main.go 

files := map[string]File{
        &quot;main.go&quot;: File{&quot;test updated&quot;}}
gistRequest := GistRequest{
    Files:       files,
    Description: &quot;this is a test&quot;,
    Public:      false}
gistRequestJson, err := json.Marshal(gistRequest)
if err != nil {
    fmt.Printf(&quot;%s&quot;, err)
    return
}</code></pre>
<p>Here we are just updating the content of main.go that will become “test updated”. The next thing to do is to build or http client, create the request, execute it and display the result</p>
<pre v-highlightjs><code class="go" v-pre >// consuming-api/github-patch-gist/main.go 

c := http.Client{Timeout: time.Duration(4) * time.Second}

req, err := http.NewRequest(&quot;PATCH&quot;, &quot;https://api.github.com/gists/79c9cec21a116f6ee166fd73ba750565&quot;, bytes.NewBuffer(gistRequestJson))
if err != nil {
    fmt.Printf(&quot;%s&quot;, err)
    return
}
req.Header.Add(&quot;Accept&quot;, `application/json`)
// add header for authentication
req.Header.Add(&quot;Authorization&quot;, fmt.Sprintf(&quot;token %s&quot;, os.Getenv(&quot;TOKEN&quot;)))

resp, err := c.Do(req)
if err != nil {
    fmt.Printf(&quot;Error %s&quot;, err)
    return
}
defer resp.Body.Close()
body, err := ioutil.ReadAll(resp.Body)
fmt.Printf(&quot;Body : %s \n &quot;, body)
fmt.Printf(&quot;Response status : %s \n&quot;, resp.Status)</code></pre>
<p>The execution result is :</p>
<pre v-highlightjs><code class="go" v-pre >Body : {&quot;url&quot;:&quot;https://api.github.com/gists/c960d211532f7c35aeb0c854892bf108&quot;,...}
 Response status : 200 OK</code></pre>
<p>A response status of 200 indicates that the server has correctly handled the request.</p>
<p>On the GitHub gist website, our gist has been updated! (see figure <a href="#fig:Gist-updated-through" data-reference-type="ref" data-reference="fig:Gist-updated-through">3</a>)</p>
<figure>
<b-img :src="require('@/assets/images/gist_updated.png')" alt="Gist updated through API call[fig:Gist-updated-through]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Gist updated through API call<span id="fig:Gist-updated-through" label="fig:Gist-updated-through">[fig:Gist-updated-through]</span></figcaption>
</figure>
<div id="delete-a-gist" class="anchor"></div>
<h3 data-number="7.0.5"><span class="header-section-number">7.0.5</span> Delete a Gist <a href="#delete-a-gist"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Deleting a resource using a web service is a common task. The http request method to do that is typically <strong>DELETE</strong>. The Github API documentation is pretty straightforward (see figure <a href="#fig:Delete-a-Gist" data-reference-type="ref" data-reference="fig:Delete-a-Gist">4</a>)</p>
<figure>
<b-img :src="require('@/assets/images/delete_gist_documentation.png')" alt="Delete a Gist documentation[fig:Delete-a-Gist]"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Delete a Gist documentation<span id="fig:Delete-a-Gist" label="fig:Delete-a-Gist">[fig:Delete-a-Gist]</span></figcaption>
</figure>
<p>The code is simpler than the previous request because we do not have to include a body :</p>
<pre v-highlightjs><code class="go" v-pre >// consuming-api/github-gist-delete/main.go 

c := http.Client{Timeout: time.Duration(4) * time.Second}
req, err := http.NewRequest(&quot;DELETE&quot;, &quot;https://api.github.com/gists/79c9cec21a116f6ee166fd73ba750565&quot;, nil)
if err != nil {
    fmt.Printf(&quot;Error %s&quot;, err)
    return
}
req.Header.Add(&quot;Accept&quot;, `application/json`)
// add header for authentication
req.Header.Add(&quot;Authorization&quot;, fmt.Sprintf(&quot;token %s&quot;, os.Getenv(&quot;TOKEN&quot;)))

resp, err := c.Do(req)
if err != nil {
    fmt.Printf(&quot;Error %s&quot;, err)
    return
}
defer resp.Body.Close()
body, err := ioutil.ReadAll(resp.Body)
if err != nil {
    fmt.Printf(&quot;Error %s&quot;, err)
    return
}
fmt.Printf(&quot;Body : %s \n &quot;, body)
fmt.Printf(&quot;Response status : %s \n&quot;, resp.Status)</code></pre>
<p>The execution result shows an empty body and a response status code equal to 204 No Content :</p>
<pre v-highlightjs><code class="go" v-pre >Body :

Response status : 204 No Content</code></pre>
<p>The response status code is 2... meaning our request has been a success. We have deleted our gist.</p>
<div id="test-yourself" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="8.1"><span class="header-section-number">8.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>True or False. The server is responsible for sending requests to the client.</p></li>
<li><p>Give two architectural constraints of a REST API (as defined in the Roy Fielding thesis).</p></li>
<li><p>How to create a basic HTTP client with a 3 seconds timeout?</p></li>
<li><p>In which header can you put user credentials?</p></li>
<li><p>If you send a POST request with the default Go, http client, which headers are already set?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="8.2"><span class="header-section-number">8.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>True or False. The server is responsible for sending requests to the client.</p>
<ol type="1">
<li><p>False</p></li>
<li><p>The server receives and handles requests; the client sends requests.</p></li>
</ol></li>
<li><p>Give two architectural constraints of a REST API (as defined in the Roy Fielding thesis).</p>
<ol type="1">
<li><p>Stateless</p></li>
<li><p>Responses are cacheable</p></li>
<li><p>Uniform interface</p></li>
<li><p>Layered systems</p></li>
<li><p>Code on demand</p></li>
</ol></li>
<li><p>How to create a basic HTTP client with a 3 seconds timeout?</p>
<pre v-highlightjs><code class="go" v-pre >c := http.Client{Timeout:1 * time.Second}</code></pre></li>
<li><p>In which header can you put user credentials?</p>
<ol type="1">
<li>In the <span v-highlightjs><code class="go" v-pre style="display: inline">Authorization</code></span> header</li>
</ol></li>
<li><p>If you send a POST request with the default Go, http client, which headers are already set?</p>
<ol type="1">
<li><p>User-Agent</p></li>
<li><p>Content-Length</p></li>
<li><p>Content-Type</p></li>
<li><p>Accept-Encoding</p></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>In the client/server model, the client requests a resource (or a service). The server provides access to this resource.</p></li>
<li><p>The server receives and handles requests; the client sends requests.</p></li>
<li><p>Servers are also called producers. Clients are also called consumers.</p></li>
<li><p>Roy Fielding has developed the term REST API in his doctoral thesis</p></li>
<li><p>Roy Fielding defined some architectural constraints used to build RESTful APIs.</p></li>
<li><dl>
<dt>To create an HTTP client, initialize a variable of type http.Client</dt>
<dd><span v-highlightjs><code class="go" v-pre style="display: inline">c := http.Client{Timeout:1 * time.Second}</code></span>. Then you can use the client to send requests :
</dd>
</dl></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >// Send simple GET and POST requests
res, err := c.Get(&quot;http://localhost:8091/status&quot;)
// ....
res, err = c.Post(&quot;http://localhost:8091/status&quot;, &quot;application/json&quot;, bytes.NewBuffer([]byte(&quot;42&quot;)))

// Build more complex requests
req, err := http.NewRequest(&quot;HEAD&quot;, &quot;http://localhost:8091/status&quot;, nil)
// ....
req.Header.Add(&quot;Accept&quot;, `application/json`)
// send the requests
c.Do(req)</code></pre>
<ul>
<li><p>By default, the client has <strong>no timeout</strong> set. It’s important in your application to always configure this option (set the field <span v-highlightjs><code class="go" v-pre style="display: inline">Timeout</code></span> with an appropriate duration).</p></li>
<li><p>You can add additional headers to the request with <span v-highlightjs><code class="go" v-pre style="display: inline">request.Header.Add</code></span></p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>If you are curious, I suggest you take a look at the source code here : https://golang.org/src/net/http/client.go.<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap35BuildAnHttpClient"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap34Benchmarks'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Benchmarks</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap36ProgramProfiling'}">
									<p><u><small>Next</small></u></p>
									<p><small>Program Profiling</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Build an HTTP Client - Practical Go Lessons"
const description = "How to create a basic HTTP client with Go. What is a REST API and how to create a simple client with the standard library"

export default {
  name: "Chap35BuildAnHttpClient",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
